<template>
  <ul class="sidenav app-sidenav" :class="{open: value}">
    <router-link
      v-for="link in links"
      tag="li"
      active-class="active"
      :to="link.url"
      :key="link.url"
      :exact="link.exact"
    >
      <a href="link.url" class="waves-effect waves-orange pointer">{{$t(`menu.${link.title}`)}}</a>
    </router-link>
  </ul>
</template>

<script>
export default {
  name: 'Sidebar',
  props: ['value'],
  data () {
    return {
      links: [
        { title: 'Bill', url: '/', exact: true },
        { title: 'History', url: '/history' },
        { title: 'Planning', url: '/planning' },
        { title: 'New record', url: '/record' },
        { title: 'Categories', url: '/categories' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
