import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueMeta from 'vue-meta'
import Paginate from 'vuejs-paginate'
import App from './App.vue'
import router from './router'
import store from './store'
import tooltipDirective from '@/directives/tooltip.directive'
import dateFilter from '@/filters/date.filter'
import currencyViewFilter from '@/filters/currency-view.filter'
import messagePlugin from '@/plugins/query.message'
import loaderComponent from '@/components/app/Loader'
import 'materialize-css/dist/js/materialize.min'
import './registerServiceWorker'

import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import i18n from './i18n'
Vue.config.productionTip = false

Vue.use(messagePlugin)
Vue.use(Vuelidate)
Vue.use(VueMeta)
Vue.filter('date', dateFilter)
Vue.filter('currencyView', currencyViewFilter)
Vue.directive('tooltip', tooltipDirective)
Vue.component('loader', loaderComponent)
Vue.component('paginate', Paginate)

// App's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyBS9bXQG9eDw8GKFUAwnHtyFVctCZlWBc4',
  authDomain: 'dy-my-crm.firebaseapp.com',
  databaseURL: 'https://dy-my-crm-default-rtdb.firebaseio.com',
  projectId: 'dy-my-crm',
  storageBucket: 'dy-my-crm.appspot.com',
  messagingSenderId: '559062208768',
  appId: '1:559062208768:web:cd0658d1f0fbfc74fee3e5'
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

let app

// For checking session
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
})
