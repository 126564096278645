<template>
  <div>
    <loader v-if="loading"/>
    <div v-else class="app-main-layout">
      <Navbar @showSidebar="isOpen = !isOpen"/>
      <Sidebar v-model="isOpen"/>
      <main class="app-content" :class="{full: !isOpen}">
        <div class="app-page">
          <router-view/>
        </div>
      </main>
      <div class="fixed-action-btn">
        <router-link class="btn-floating btn-large blue" to="/record" v-tooltip="this.$t('global.AddRecord')">
          <i class="large material-icons">add</i>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/app/Navbar'
import Sidebar from '@/components/app/Sidebar'
import Loader from '../components/app/Loader'
import { mapGetters } from 'vuex'

export default {
  name: 'MainLayout',
  data () {
    return {
      isOpen: true,
      loading: true
    }
  },
  computed: {
    error () {
      return this.$store.getters.error
    },
    ...mapGetters(['info'])
  },
  watch: {
    error (fbError) {
      this.$error(`firebaseError.${fbError.code}` || this.$t('Oops, something is wrong'))
    }
  },
  async mounted () {
    if (!Object.keys(this.$store.getters.info).length) {
      await this.$store.dispatch('fetchInfo')
    }
    this.loading = false
    this.$i18n.locale = this.info.lang
  },
  components: {
    Loader,
    Navbar,
    Sidebar
  }
}
</script>

<style scoped>

</style>
