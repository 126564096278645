export default {
  actions: {
    async fetchCurrency () {
      const pattern = /(\d{2})\.(\d{2})\.(\d{4})/
      const resData = await fetch('https://bank.gov.ua/NBUStatService/v1/statdirectory/exchange?json')
      const dataJson = await resData.json()
      return dataJson.filter(el => el.cc === 'USD' || el.cc === 'EUR' || el.cc === 'RUB').map(elem => ({
        name: elem.cc,
        rate: elem.rate,
        date: new Date(elem.exchangedate.replace(pattern, '$3-$2-$1'))
      }))
    }
  }
}
