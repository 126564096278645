import firebase from 'firebase/app'

export default {
  actions: {
    async login ({ commit, dispatch }, { email, password }) {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async forgotPassword ({ commit, dispatch }, { email }) {
      try {
        await firebase.auth().sendPasswordResetEmail(email)
        console.log(`try to reset with email ${email}`)
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    async logout ({ commit }) {
      await firebase.auth().signOut()
      commit('clearInfo')
    },

    async register ({ commit, dispatch }, { email, password, name, bank }) {
      try {
        await firebase.auth().createUserWithEmailAndPassword(email, password)
        const uid = await dispatch('getUid')
        await firebase.database().ref(`/users/${uid}/info`).set({
          name,
          bank
        })
      } catch (e) {
        commit('setError', e)
        throw e
      }
    },

    getUid () {
      const user = firebase.auth().currentUser
      return user ? user.uid : null
    }
  }
}
